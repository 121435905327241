/* ------ begin boilerplate ------ */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
}

html, body {
  /* font-size: 62.5%; (62.5/100) * 16px = 10px */
  font-size: 0.5vw;
  -webkit-text-size-adjust: none; /* for iOS Safari */
  text-size-adjust: none; /* for other mobile browsers */
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
body {
  background-color: #D1D1D1;
}

p {
  font-family: 'Times New Roman', Times, serif, 'Times New Roman', Times, serif;
}

@media (prefers-reduced-motion: no-preference) {
  html {
        scroll-behavior: smooth;
  }
}

img {
  display: block;
  width: 100%;
}

a {
  text-decoration: none;
}

menu:not(article menu),
ol:not(article ol),
ul:not(article ul) {
  list-style: none;
}

menu,
ol,
ul {
  padding-left: 0;
}

article ol,
article ul {
  list-style-position: inside;
}

button,
select,
[type=radio],
[type=submit],
[type=checkbox] {
  cursor: pointer;
}

body {
  overflow: scroll;
}

/* ------ end boilerplate ------- */

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App {
  display: flex;
  flex-direction: column;
  flex: 1;
  /* min-height: 100vh; */

/* color variables */
  --main-lightest: white;
  --second-lightest: #E8E8E8;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none
}

.header-background {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-image: url('./images/header-top.svg');
  background-position: top-left;
  background-repeat: no-repeat;
  background-size: 100%;
}

.header-nav {
  display: flex;
  width: 100%;
  height: 12em;
  /* margin: auto; */
  margin-top: 0px;
  justify-content: space-around;
  align-items: center;
}

@font-face {
  font-family: Knewave;
  src: url('./fonts/Knewave-Regular.ttf');
}

@font-face {
  font-family: Roboto-Regular;
  src: url('./fonts/Roboto-Regular.ttf');
}

@font-face {
  font-family: Roboto-Bold;
  src: url('./fonts/Roboto-Bold.ttf');
}

@font-face {
  font-family: Roboto-Italic;
  src: url('./fonts/Roboto-Italic.ttf');
}

.crispytodo-title {
  color: #FFF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-text-stroke-width: .001em;
  -webkit-text-stroke-color: #000;
  font-family: Knewave;
  font-size: 4em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-top: 3vh;
}

.header-nav-links {
  width: 22vw;
  height: 100%;
  display: flex;
  align-items: center;
  /* border: 3px solid green; */
  justify-content: space-between;
  margin-top: 4.2vh;
  margin-right: 2.2vh
}

.header-nav-links > * {
  margin-right: 10px;
  margin-left: 10px;
}

.header-nav-text {
  background-image: url('./images/fried-chicken-no-outline.svg');
  background-repeat: no-repeat;
  /* background-size: 80%; */
  background-position: center;
  height: 10vh;
  width: 8vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-nav-text:hover {
  background-image: url('./images/fried-chicken.svg');
}

/* unique styling for our links */
.header-nav-text > a {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-text-stroke-width: .03em;
  -webkit-text-stroke-color: #000;
  color: #FFF;
  font-family: Roboto-Bold;
  font-size: 5em;
  font-style: normal;
  line-height: normal;
}

.todo-input-container {
  height: auto;
  margin-top: 7.5vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.todo-form {
  display: flex;
  width: 60vw;
  justify-content: space-between;
  align-items: center;
  height: 7em;
  padding: 10px;
}

.todo-form > input {
  border-radius: 40px;
  border: 2px solid black;
  background: var(--second-lightest);
  padding: 10px;
  padding-left: 20px;
  font-family: Roboto-Regular;
  font-size: 3.2rem;
  flex: 1;
}

#submit-button {
  background: none;
  background-image: url('./images/deep-fryer.svg');
  background-size: 6.5vw;
  background-position: center;
  background-repeat: no-repeat;
  width: 17%;
  height: 12vh;
  border: none;
}


.todo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  margin-top: 4vh;
  padding: 1.5vh 0 1.5vh 0;
  width: 77vw;
  border-radius: 40px;
}

/* made this stupid wrapper just for a gradient background */
.todo-wrapper {
  padding: 2.2px;
  background: linear-gradient(90deg, #D1C663, #4433A2);
  border-radius: 15px; /* slightly larger to accommodate the inner radius */
  display: inline-block; 
  margin: .8vw; /* Adjust as needed */
}

.todo-li {
  border-radius: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.7em;
  margin: 2px;
  width: 72vw;
  min-height: 7vh;
  cursor: pointer;
  background: white;
}

.todo-todo {
  flex: 5;
  font-family: Roboto-Regular;
  font-size: 2.7vh;
  padding-left: 4px;
}

.todo-button-container {
  flex: 2;
  display: flex;
  justify-content: flex-end;
}

.todo-button {
  background: white;
}

.todo-button-text {
  margin: 0 .5vw 0 .5vw;
  height: 2.7vh;
  border: 2px solid grey;
  cursor: pointer;
  border-radius: 7px;
  padding: .1vh 1vh .1vh 1vh;
  font-size: 1.7vh;
}

img.todo-icon {
  height: 1.5em;
  width: 1.5em;
  margin: .2vw .5vw .2vw .5vw;
  background: transparent;
  display: block;
  transition: filter 0.2s;
}

img.todo-icon:hover {
  filter: brightness(0) saturate(100%) invert(17%) sepia(92%) saturate(7487%) hue-rotate(216deg) brightness(119%) contrast(119%);
}

/* another stupid wrapper just for a gradient border */
.login-register-profile-wrapper {
  padding: 1vw;
  margin-top: 12vh;
  width: 52vw;
  margin:auto;
  margin-top: 9vh;
  background: linear-gradient(90deg, #D1C663, #4433A2);
  border-radius: 15px; /* slightly larger to accommodate the inner radius */
  display: inline-block; /* or 'block', depending on your layout needs */
}

.login-register-container {
  display: flex;
  flex-direction: column;
  margin: auto;
  background-color: var(--main-lightest);
  padding: 2vw;
  border-radius: 13px;
  font-family: Roboto-Regular;
  font-size: .7em;
}

.form-container {
  margin: 20px auto 20px auto;
  width: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2.7em;
  color: black;
}

.form-item {
  display: flex;
  flex-direction: column;
  height: 10vh;
  width: 40vw;
  margin: 1vw 0 1vw 0;
  font-size: 1.5em;
}

.form-item > label {
  margin-bottom: 1vh;
  margin-left: 1vw
}

.form-item > input {
  height: 5vw;
  width: 30vw;
  margin-left: auto;
  margin-right: auto;
  padding: 5px;
  border-radius: 8px;
  background-color: var(--second-lightest);
  font-size: 1em;
}

.form-container > button {
  width: 17vw;
  margin: 1vh auto 2vh auto;
  margin-top: 3vh;
  height: 4vh;
  border: 2px solid grey;
  cursor: pointer;
  border-radius: 7px;
  font-size: 1.3em
}

.message {
  margin: auto
}

.login-register-text {
  margin: auto;
  margin-top: 2vh;
  text-align: center;
}

.login-register-text > p {
  font-family: Roboto-Regular;
  font-size: 1.2em
}

.register-text {
  color: blue;
  font-weight: bold;
  cursor: pointer;
}

.profile-picture {
  background-image: url('./images/profile-user.svg');
  width: 6em;
  height: 6em;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.logout-container {
  margin: 20px auto 20px auto;
  /* width: 50vw; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 5em;
  color: black;
}

.logout-container > * {
  margin-bottom: 2vh
}

.logout-container > button {
  width: 17vw;
  margin: 1vh auto 2vh auto;
  margin-top: 3vh;
  height: 4vh;
  border: 2px solid grey;
  cursor: pointer;
  border-radius: 7px;
}

.crispy-footer {
  display: flex;
  justify-content: center;
  height: 6vw;
  background: linear-gradient(90deg, #D1C663, #4433A2);
  line-height: 50px;
  align-items: center;
  justify-content: space-between;
  padding: 0 4vw 0 4vw;
  flex-shrink: 0;
  margin-top: 6vh;  
}

.footer-text-left{
  font-family: Knewave;
  font-size: 3.5em;
  line-height: 50px;
}

.footer-text-right{
  font-size: 2.7em;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer-text-right > * {
  font-family: Roboto-Italic;
  height: 2.5vw;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

@media (max-width: 480px) {
  body {
    font-size: 1vw; /* Smaller font size for mobile devices */
  }
  .crispy-footer {
    height: 12em;
  }
  .todo-form > input {
    font-size: 2vh;
    max-height: 5vh;
  }
  .todo-todo {
    font-size: 2.2vh;
  }
  .header-nav-text {
    width: 10vw;
  }
  .todo-li {
    width: 87vw;
  }
  .todo-form {
    width: 77vw;
  }
  .footer-text-right > * {
    height: 4.5vw;
    align-items: space-between;
  }
}
